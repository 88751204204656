.Logo {
  display: flex;
  flex-direction: column;
  margin: 2em 2em 2em 2em;
  text-align: center;
  transition: margin 0.4s ease;
  -webkit-transition: margin 0.3s;
  -moz-transition: margin 0.3s;
}
.Logo-shrink {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.Logo__name {
  font-size: 2rem;
}
.Logo__description {
  white-space: nowrap;
  font-size: 0.7rem;
}
