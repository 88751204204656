.Methods {
  width: 100%;
  text-align: center;
}
.Methods__title {
  width: 100%;
  margin: 4rem 0 3rem 0;
}
.Methods-wrapper {
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 30px;
}
.Methods-list {
  list-style-type: none;
}
.Methods-list__item {
  padding-bottom: 30px;
}
.Methods-list__item_underline {
  text-decoration: underline;
}
.Method {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  height: 100%;
  min-width: 265px;
  align-items: center;
}
.Method__title {
  text-align: center;
  margin: 1rem 0 1rem 0;
}
.Method__picture {
  width: 100%;
  height: auto;
  max-width: 300px;
  margin: 0 auto;
}
.Method-wrapper {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 0 auto 3rem;
  min-width: 265px;
  align-items: center;
}
.Method__description {
  max-width: 80%;
  word-break: break-word;
  font-size: 1.1rem;
  margin-bottom: 15px;
}
.Method__description-title {
  font-size: 0.95rem;
  text-align: left;
}
.Method__description_list {
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  font-size: 0.95rem;
}
.Method__description_list > * {
  font-size: 0.95rem;
  list-style: inside circle;
}
.Method__find-out-more {
  color: #0f0f0e;
  background-color: rgba(213, 231, 188, 0.5);
  border-radius: 1.66em;
  display: inline-block;
  text-align: center;
  font-size: 0.9rem;
  cursor: pointer;
  line-height: 1.6rem;
  width: 120px;
  margin-top: auto;
  font-weight: 800;
  padding: 5px;
}
