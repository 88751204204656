.Additional-bar {
  background-color: #504b47;
  width: 100%;
  font-size: 0.7em;
  top: 0;
  z-index: 3;
  min-height: 3.5714em;
  display: flex;
  align-items: center;
}
.Additional-bar__margin {
  margin: 0 auto;
}
.Additional-bar__wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 1200px;
  align-items: center;
}
@media (max-width: 1135px) and (min-width: 1024px) {
  .Additional-bar__wrapper {
    width: 964px;
  }
}
.Additional-bar__text {
  color: #b1b0af;
  font-size: inherit;
  font-size: 1.1em;
}
.Additional-bar__button {
  cursor: pointer;
  background-color: rgba(184, 233, 148, 0.8);
  text-align: center;
  padding: 1.1em 0 1.1em;
  width: auto;
  color: #ffffff;
  font-weight: 600;
  border: 1px solid transparent;
}
.Additional-bar__button-text {
  padding: 0 2.4em;
  font-size: 1rem;
  display: block;
}
.Additional-bar__appointment {
  margin-left: 3em;
  align-self: flex-end;
}
.Additional-bar__item {
  display: flex;
  align-items: flex-end;
  color: #b1b0af;
  margin-left: 3.33em;
  margin-top: 0.2em;
}
.Additional-bar__icon {
  font-size: 1.2em;
  margin-right: 0.5rem;
}
.Additional-bar__items-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  margin-left: auto;
}
@media (max-width: 1366px) {
  .Additional-bar__appointment {
    margin: 0 0 0 2em;
  }
}
