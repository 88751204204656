.Appointment .Content-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  margin: 0 auto;
}
.Appointment {
  background-color: #fcf7f1;
}
.Appointment__required {
  flex-direction: row;
  width: 400px;
  margin: 0 auto;
  max-width: 100%;
}
.Appointment__title {
  margin: 20px auto;
  text-align: center;
  max-width: 100%;
}
.Appointment__message {
  width: calc(100% - 40px);
  max-width: 400px;
  margin: 15px 0;
  border-radius: 4px;
  padding: 12px 20px;
}
.Appointment__send {
  background-color: #b8e994;
  color: white;
  padding: 14px 20px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: 440px;
  max-width: 100%;
}
.Appointment__send:hover {
  background-color: #78e08f;
}
.Appointment__required-item {
  width: calc(100% - 40px);
  padding: 12px 20px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 400px;
}
