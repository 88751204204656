.About {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  justify-content: center;
}
.About-wrapper {
  width: 100%;
  background-color: #fcf7f1;
}
.About__header {
  text-align: center;
  padding-bottom: 13px;
}
.About__text {
  font-size: 1rem;
  word-break: break-word;
  -webkit-animation: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-animation: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 1;
}
.About__text-highlight {
  word-break: break-word;
  font-size: 1rem;
  align-self: center;
  padding-bottom: 10px;
  text-align: center;
}
.About__text-highlight strong {
  font-weight: bold;
}
.About__text-highlight * {
  margin: 10px;
}
.About__description {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 200px;
  align-self: center;
  -webkit-animation: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-animation: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 1;
  margin-left: 40px;
}
.About__picture {
  height: 100%;
  display: block;
  margin: 30px 0;
  max-height: 350px;
}
