.Slides {
  background-color: #fcf7f1;
  height: calc(100% + 30px);
}
.Slide {
  width: 100%;
  cursor: pointer;
}
.Slide__image {
  max-height: 600px;
  height: auto;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  object-position: top;
}
.Slide__text {
  position: absolute;
  font-size: 1.7rem;
  word-break: break-word;
  color: #504b47;
  transition: none 0s ease 0s;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0;
}
.Slide__text h1 {
  max-width: 550px;
}
.Slide__text_psy {
  max-width: 400px;
}
.Slide__text_couple {
  top: 10%;
  width: 100%;
  font-size: 1.7rem;
  color: #504b47;
}
.Slide__text_couple h1 {
  padding-left: 20px;
}
.Slide__text_psy {
  left: 2%;
  bottom: 7%;
  font-size: 2.1rem;
  color: #373029;
  font-weight: 700;
}
