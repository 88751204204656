.Header {
  background-color: white;
  width: calc(100% - 20px);
  padding-right: 40px;
}
.Header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  background-color: #fdfffd;
}
@media (max-width: 1200px) {
  .Header-wrapper {
    width: 100%;
  }
}
@media (max-width: 1135px) and (min-width: 1024px) {
  .Header {
    width: calc(100% - 20px);
    padding-right: 20px;
  }
}
.Header-shrink {
  top: 0;
  position: sticky;
  z-index: 1002;
}
