html {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -ms-word-wrap: break-word;
}

body {
    outline: 0;
    margin: 0;
    padding: 0;
    background-color: rgba(213, 231, 188, 0.5);
    text-rendering: optimizeLegibility;
    /*font-size: 100%;*/
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 1.5em;
    font-weight: 400;
}


h1, h2, h3, p {
    margin: 0;
    padding: 0;
}



a, button {
    cursor: pointer;
}


a, a:hover, a:focus, a:active {
    outline: 0;
}

a:visited {
    color: inherit;
}

a {
    text-decoration: none;
    color:inherit;
}
