.Types {
  background-color: #e2fad9;
  padding: 70px 0;
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
}
.Types-group {
  width: calc(100% - 100px);
  margin: 0 auto;
}
.Types__header {
  text-align: center;
  padding-bottom: 50px;
}
.Type {
  width: 100%;
}
.Type__title {
  padding: 0.9rem 0;
}
.Type__picture {
  width: 150px;
  height: 150px;
  border: 1px solid transparent;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  margin: 0 auto;
  -webkit-animation: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-animation: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.Type__content {
  text-align: center;
}
.List-types {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  word-break: break-word;
  max-width: 400px;
  padding-bottom: 20px;
}
.List-types__item {
  font-size: 0.85rem;
  padding-bottom: 7px;
}
