.Main-article {
  margin-top: 50px;
  line-height: 32px;
}
.Main-text {
  line-height: 32px;
  margin-bottom: 10px;
}
.Main-title {
  margin-bottom: 30px;
}
