.Menu {
  color: #373029;
  font-size: 1rem;
  max-width: 1000px;
  width: 100%;
}
.Menu-items {
  list-style: none;
  flex-grow: 1;
  margin: 0 auto;
  display: flex;
}
.Menu__item {
  display: block;
  padding: 1rem;
  position: relative;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
}
.Menu__item:last-child {
  padding-right: 0;
  margin-right: 0;
}
.Menu__item:hover > ul,
.Menu__item:focus-within > ul {
  visibility: visible;
  opacity: 1;
  display: block;
  max-height: 200px;
}
.Menu-dropdown {
  list-style: none;
  padding-left: 0;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.3s ease 0s;
  margin-top: 1rem;
  left: 0;
  display: block;
  z-index: 1002;
  background-color: #373029;
  color: white;
  max-height: 0px;
}
.Menu-dropdown:hover,
.Menu-dropdown:focus {
  visibility: visible;
  opacity: 1;
  max-height: 200px;
}
.Menu-dropdown__item {
  display: block;
  float: left;
  padding: 1rem;
  position: relative;
  text-decoration: none;
  clear: both;
  width: 100%;
}
.Menu-responsive {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  -webkit-transition: visibility 0s, opacity 0.5s ease;
  transition: visibility 0s, opacity 0.5s ease;
  left: 0;
  z-index: 1002;
  background-color: #373029;
  color: white;
  max-height: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;
  padding: 10px 5px 20px;
}
.Menu-responsive .Menu__item {
  margin-left: 5px;
}
.Menu-responsive_active {
  visibility: visible;
  opacity: 0.8;
  max-height: 1000px;
  height: 1000px;
}
.Header-hide-btn {
  display: none;
  padding: 20px 20px 20px 10px;
  font-size: 30px;
}
.Header-hide-btn i {
  cursor: pointer;
}
@media screen and (min-width: 1000px) {
  .Menu-responsive {
    display: none;
  }
  .Header-wrapper {
    flex-direction: row;
  }
  .About {
    flex-direction: row;
  }
  .About__description {
    margin-left: 40px;
  }
  .Logo {
    margin-left: 2em;
    margin-right: 2em;
  }
  .Methods-wrapper {
    height: auto;
  }
  .Additional-bar {
    display: flex;
  }
}
@media screen and (max-width: 1000px) {
  .Header-hide-btn {
    display: block;
  }
  .About {
    flex-direction: column;
  }
  .About__description {
    margin-left: 0;
  }
  .About__picture {
    align-self: center;
  }
  .Logo__name {
    font-size: 1.5rem;
  }
  .Menu-items {
    display: none;
  }
  .Header-wrapper {
    flex-direction: row-reverse;
  }
  .Logo {
    margin: 0.5em 1em 0.5em 1em;
    width: 100%;
    width: 700px;
  }
  .Logo__description {
    white-space: normal;
  }
  .Appointment__message {
    max-width: 300px;
  }
  .Methods-wrapper {
    height: 580px;
  }
  .Method {
    height: 500px;
  }
  .Additional-bar {
    display: none;
  }
}
@media screen and (max-width: 360px) {
  .Logo__name {
    font-size: 1.3rem;
  }
  .Logo__description {
    font-size: 0.6rem;
  }
  .navbar-toggle {
    margin-right: 0px;
  }
  .navbar-brand {
    padding-right: 5px;
  }
}
@media (max-width: 815px) {
  .Menu__item {
    padding: 1rem 0 0 0;
  }
}
.dropdown-menu > li > a {
  color: white;
}
.dropdown-menu > li:hover > * {
  color: #373029 !important;
  text-decoration: none;
  background-color: #f1f1f1;
}
.dropdown-menu > li > a:hover,
.Menu__item:hover > ul .dropdown-menu > li > a:focus {
  color: #373029;
  text-decoration: none;
  background-color: #f1f1f1;
}
@media (max-width: 767px) {
  .Menu-dropdown {
    display: none;
    visibility: hidden;
  }
  .Menu__item:hover > ul,
  .Menu__item:focus-within > ul {
    visibility: hidden;
    display: none;
  }
}
